<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Category</label>

            <b-form-select
              v-model="filter.category_code"
              :options="formattedSiteDocumentCategoryOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Item</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Site Document Type"
              v-model="filter.item"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary btn-md"
            @click="getSiteDocumentTypes(true)"
          >
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button
          class="btn btn-success btn-md"
          @click="showInputModal(null, 'Form Add Site Document Type')"
        >
          <i class="flaticon-add"></i> Add Site Document Type
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="filteredDocument">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Code</th>
              <th class="pl-7">Item</th>
              <th class="pl-7">Category</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item: siteDocumentType, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ siteDocumentType.code }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ siteDocumentType.item }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    siteDocumentType.category
                      ? siteDocumentType.category.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="
                    showInputModal(
                      siteDocumentType,
                      'Form Update Site Document Type',
                      false
                    )
                  "
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(siteDocumentType)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="input" hide-footer :title="modalTitle">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Code *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Code"
                v-model="form.code"
                :disabled="!isCreate"
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Item *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Site Document Type Name"
                v-model="form.item"
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Category *</label>

              <b-form-select
                v-model="form.category"
                :options="formattedSiteDocumentCategoryOptions"
                size="lg"
              ></b-form-select>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Folder Ftp *</label>

              <div v-if="!isCreate">Current Folder : {{this.form.folder_ftp.name}}</div>

              <v-treeview
                activatable
                :items="formattedFtpOptions"
                @update:active="selected_folder"

              ></v-treeview>
            </div>
          </div>
        </form>

        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            block
            @click="
              isCreate ? saveSiteDocumentType() : updateSiteDocumentType()
            "
          >
            Save
          </b-button>

          <b-button
            :disabled="loading"
            class="btn btn-secondary mt-0"
            block
            @click="closeInputModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Are You Sure Want To Delete Site Document Type</label>
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteSiteDocumentType"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_SITE_DOCUMENT_TYPE,
  GET_SITE_DOCUMENT_TYPE,
  SAVE_SITE_DOCUMENT_TYPE,
  UPDATE_SITE_DOCUMENT_TYPE
} from "../../../core/services/store/site-document-type.module";
import { GET_FILE_FTP } from "../../../core/services/store/ftp.module";
import { GET_SITE_DOCUMENT_CATEGORY } from "../../../core/services/store/site-document-category.module";

export default {
  name: "SiteDocumentType",
  components: { CompleteTable },
  data() {
    return {
      selected_folder_item : null,
      filter: {
        code: null,
        item: null,
        category: null
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        item: null,
        category: null,
        folder_ftp: {
          id: null,
          name: null
        }
      },
      isCreate: true
    };
  },

  computed: {
    ...mapState({
      loading: state => state.siteDocumentType.loading,
      siteDocumentTypes: state =>
        state.siteDocumentType.siteDocumentTypes?.data,
      siteDocumentCategories: state =>
        state.siteDocumentCategory.siteDocumentCategories?.data,
      ftps: state => state.ftp.ftps?.data
    }),
    formattedSiteDocumentCategoryOptions() {
      let siteDocumentCategories = [];

      if (arrayMoreThanOne(this.siteDocumentCategories)) {
        siteDocumentCategories = this.siteDocumentCategories.map(
          siteDocumentCategory => {
            return {
              value: siteDocumentCategory.code,
              text: siteDocumentCategory.name
            };
          }
        );
      }
      return [
        { value: null, text: "Choose Category" },
        ...siteDocumentCategories
      ];
    },

    formattedFtpOptions() {
      return this.ftps;
    },

     filteredDocument() {
      /* console.log("filtered doc", this.filter);
      const code = this.filter.code;
      const category_code = this.filter.category;
      const item = this.filter.item;

      return this.siteDocumentTypes?.filter(
        doc =>
          (code ? doc.code?.toLowerCase()?.includes(code?.toLowerCase()) : true) &&
          (category_code ? doc.category_code === category_code : true) &&
          (item ? doc.item?.toLowerCase()?.includes(item?.toLowerCase()) : true)
      ); */
      return this.siteDocumentTypes;
    }
  },
  methods: {
    showInputModal(siteDocumentType, modalTitle, isCreate = true) {
      this.currentItem = siteDocumentType;

      this.form = {
        code: siteDocumentType?.code ?? null,
        item: siteDocumentType?.item ?? null,
        category: siteDocumentType?.category?.code ?? null,
        folder_ftp: siteDocumentType?.folder_ftp ?? null
      };
      this.modalTitle = modalTitle;

      this.isCreate = isCreate;

      this.$refs["input"].show();
    },

    showDeleteModal(siteDocumentType) {
      this.currentItem = siteDocumentType;

      this.$refs["delete"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    getSiteDocumentTypes(filter = false) {
      if (!arrayMoreThanOne(this.siteDocumentTypes) || filter) {
        this.$store.dispatch(GET_SITE_DOCUMENT_TYPE, {
          params: this.filter
        });
      }
    },

    getSiteDocumentCategories() {
      if (!arrayMoreThanOne(this.siteDocumentTypes)) {
        this.$store.dispatch(GET_SITE_DOCUMENT_CATEGORY, {
          params: {}
        });
      }
    },

    getFolderFtps() {
      if (!arrayMoreThanOne(this.ftps)) {
        this.$store.dispatch(GET_FILE_FTP);
      }
    },

    deleteSiteDocumentType() {
      this.$store
        .dispatch(DELETE_SITE_DOCUMENT_TYPE, {
          code: this.currentItem?.code
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    saveSiteDocumentType() {
      this.$store
        .dispatch(SAVE_SITE_DOCUMENT_TYPE, this.prepareForm())
        .then(() => {
          this.closeInputModal();
        });
    },

    updateSiteDocumentType() {
      this.$store
        .dispatch(UPDATE_SITE_DOCUMENT_TYPE, this.prepareForm())
        .then(() => {
          this.closeInputModal();
        });
    },


    selected_folder(item){
      this.selected_folder_item = item[0]
    },

    prepareForm() {
      const category = this.formattedSiteDocumentCategoryOptions.find(
        siteDocumentCategory =>
          siteDocumentCategory?.value === this.form.category
      );
      return {
        code: this.form.code,
        item: this.form.item,
        category: {
          name: category?.text,
          code: category?.value
        },
        ftp_folder: {
          id: this.selected_folder_item,
          name: this.selected_folder_item
        }
      };
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Site Document Type" }]);

    this.getSiteDocumentTypes();

    this.getSiteDocumentCategories();

    this.getFolderFtps();
  }
};
</script>
